/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:f04454fe-5a5b-447f-b652-a49cb1b77f92",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_oVhhL4ehD",
    "aws_user_pools_web_client_id": "3el64msuq68i1shudjhslbhhcp",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NICKNAME",
        "FAMILY_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Mfg-Claims-68",
            "region": "us-west-2"
        },
        {
            "tableName": "VigilantAnalytics-staging",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "wc-site-storage134215-staging",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://5nhooy5tgndozi6rg7ahjskx5y.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-chfrrmishvdixe7l7t3krnxjnq"
};


export default awsmobile;
