import React from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import TimeLossTable from './components/TimeLossTable';
import Tabs from './components/Tabs';
import { Button } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
// import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import '././components/Authenticator/styles.css';
import { PaddingOutlined } from '@mui/icons-material';

Amplify.configure(awsconfig);

const logoUrl = "https://wc-site-storage134215-staging.s3.us-west-2.amazonaws.com/public/Vigilant-logo-Work-Comp-navy.png";

function App() {

  return (
      <div className="App">
        <header className="App-header">
          <div className="image-container">
            <img style={{ width: "35%", height: "35%", paddingTop: 20 }} src={logoUrl} className="Vigilant-logo" alt="logo" />
          </div>
          <div className="authenticator">
          <Authenticator>
          {({ signOut, user }) => (
            <main>
              <Button variant='contained' style={{
                backgroundColor: "#313e44",
                marginBottom: "12px"
              }} onClick={signOut}>Sign Out
              </Button>
              
            </main>
          )}
        </Authenticator>
        </div>
        </header>
        <div className="timeLossTable">
        <Tabs />
        </div>
      </div>

  );
}

export default withAuthenticator(App);

