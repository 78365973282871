import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { createSvgIcon } from '@mui/material/utils';
import {
  DataGrid, GridRowModes,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  gridSortedRowIdsSelector,
  GridToolbarContainer,
  gridExpandedSortedRowIdsSelector,
  useGridApiContext, GridActionsCellItem, GridToolbarColumnsButton,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import { makeStyles } from '@mui/material';
import { Amplify, API, graphqlOperation, Storage } from 'aws-amplify';
import { createClaim } from '../../graphql/mutations';
import { listClaims, myQuery, claimsByFilteredStatus, claimsByActiveStatus, claimByActiveStatusAndRetroGroup } from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import RowPopUp from '../RowPopUp';
import DatabasePopUp from '../DatabasePopUp';

const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

const getFilteredRows = ({ apiRef }) => gridExpandedSortedRowIdsSelector(apiRef);

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);

function CustomToolbar() {
  const apiRef = useGridApiContext();

  const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
      >
        Export filtered list
      </Button>
    </GridToolbarContainer>
  );
}


const TimeLossTable = () => {
  // Claim state details
  const [claims, setClaims] = useState([]);

  // Data grid state details
  const [rows, setRows] = React.useState(claims);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [databasePopUpOpen, setDatabasePopUpOpen] = useState(false);
  const [rowPopUpOpen, setRowPopUpOpen] = useState(false);

  const handleDatabasePopUpClose = () => {
    setDatabasePopUpOpen(false);
  };

  const handleRowPopUpClose = () => {
    setRowPopUpOpen(false);
  };

  useEffect(() => {
    fetchClaims();
  }, []);


const fetchClaims = async () => {
    try {
      const params = {
        activeStatus: {beginsWith: 'Y'},
        retroGroup: '68', 
        filter: {
          not: {
            or: [
              { claimStatus: {eq: 'REJECTED'}},
              { followUpMgr: { eq: 'SKIP' } },
              { followUpMgr: { eq: 'RESOLVED' } }
            ]
          }
        },
        limit: 500
      }

      const claimsData = await API.graphql(graphqlOperation(claimByActiveStatusAndRetroGroup, params)); // previously "myQuery"
      const claimsList = claimsData.data.claimByActiveStatusAndRetroGroup.items;
      console.log('claims list', claimsList);
      setClaims(claimsList);

    }
    catch (error) {
      console.log('error when fetching claims', error);

    }

  }

  const updateClaim = async (newRow) => {
    try {
      const claim = newRow;
      delete newRow.updatedAt;
      delete newRow.createdAt;
      delete newRow.isNew;
      delete newRow.__typename;
      console.log(claim);

      const claimsData = await API.graphql({
        query: mutations.updateClaim,
        variables: { input: claim }
      });
      console.log('database updated');
    }
    catch (e) {
      console.log('error when updating claims: ', e);
      setDatabasePopUpOpen(true); // Open the dialog here
    }
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleResolveClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);

    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = newRow => {
    updateClaim(newRow);
    const updatedRow = { ...newRow, isNew: false };
    try{
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    console.log('row updated');
    return updatedRow;
    }
    catch (error) {
      console.log('error when updating row: ', error);
      setRowPopUpOpen(true); // Open the dialog here
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // Define column attributes for data grid creation
  const columns = [
    { field: 'planYear', headerClassName: 'timeloss-app-theme--header', headerName: 'Plan Year', width: 100, editable: true },
    { field: 'account', headerClassName: 'timeloss-app-theme--header', headerName: 'Account ID', width: 100, editable: true },
    { field: 'dba', headerClassName: 'timeloss-app-theme--header', headerName: 'DBA', width: 160, editable: true },
    { field: 'claimID', headerClassName: 'timeloss-app-theme--header', headerName: 'Claim ID', width: 120, editable: true },
    { field: 'workerName', headerClassName: 'timeloss-app-theme--header', headerName: 'Claimant Name', width: 150, editable: true },
    { field: 'claimStatus', headerClassName: 'timeloss-app-theme--header', headerName: 'Claim Status', width: 95, editable: true },
    { field: 'claimsManager', headerClassName: 'timeloss-app-theme--header', headerName: 'Claims Manager', width: 115, editable: true },
    {
      field: 'tlPTD',
      headerClassName: 'timeloss-app-theme--header',
      headerName: 'Time-Loss PTD',
      minWidth: 150,
      type: 'number',
      editable: true
    },
    {
      field: 'tlPtdDiff',
      headerClassName: 'timeloss-app-theme--header',
      headerName: '2 Week Increase',
      type: 'number',
      minWidth: 150,
      editable: true
    },
    {
      field: 'tlDays',
      headerClassName: 'timeloss-app-theme--header',
      headerName: 'Time-Loss Days Paid',
      minWidth: 150,
      type: 'number',
      editable: true
    },
    {
      field: 'tlDaysDiff',
      headerClassName: 'timeloss-app-theme--header',
      headerName: '2 Week Increase',
      type: 'number',
      minWidth: 120,
      editable: true
    },
    { 
      field: 'dateUploaded',
      headerClassName: 'timeloss-app-theme--header',
      headerName: 'Initial Upload Date',
      width: 150,
      editable: false
    },    
    { 
      field: 'dateUpdated',
      headerClassName: 'timeloss-app-theme--header',
      headerName: 'Date Updated',
      type: 'date',
      width: 150,
      editable: false,
      valueFormatter: params => 
      moment(params?.value).format("YYYY-MM-DD"),
        },
    {
      field: 'newOngoing',
      headerClassName: 'timeloss-app-theme--header',
      headerName: 'New/Ongoing Claim',
      minWidth: 150,
      editable: true
    },
    { field: 'activeStatus', headerClassName: 'timeloss-app-theme--header', headerName: "Active TL", width: 100, editable: true },
    { field: 'retroMember', headerClassName: 'timeloss-app-theme--header', headerName: 'Vigilant Member', width: 110, editable: true },
    { field: 'retroGroup', headerClassName: 'timeloss-app-theme--header', headerName: 'Member Type', width: 110, editable: true },
    { field: 'followUpMgr', headerClassName: 'timeloss-app-theme--header', headerName: 'Assigned To', width: 110, editable: true },
    { field: 'kosAnalysis', headerClassName: 'timeloss-app-theme--header', headerName: 'KOS Analysis', width: 100, editable: true },
    { field: 'followupFlag', headerClassName: 'timeloss-app-theme--header', headerName: 'Flag for Follow-Up', width: 130, editable: true },
    {
      field: 'toDo',
      headerClassName: 'timeloss-app-theme--header',
      headerName: 'Actions',
      width: 300,
      editable: true
    },
        {
      field: 'notes',
      headerClassName: 'timeloss-app-theme--header',
      headerName: 'Notes',
      width: 300,
      editable: true
    },
    {
      field: 'actions',
      type: 'actions',
      headerClassName: 'timeloss-app-theme--header',
      headerName: 'Edit Row',
      width: 110,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<CreateTwoToneIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className="timeLossTable">

<DatabasePopUp open={databasePopUpOpen} handleClose={handleDatabasePopUpClose} />

<RowPopUp open={rowPopUpOpen} handleClose={handleRowPopUpClose} />
      <Box
        sx={{
          height: 900,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
          '& .even': {
            backgroundColor: '#fcfaf5',
          },
          '& .odd': {
            backgroundColor: 'white',
          },
          '& .timeloss-app-theme--header': {
            fontWeight: 'bold',
            backgroundColor: '#add1b3',
            color: 'black'
          }
        }}
      >
        <DataGrid
          rows={claims}
          getRowHeight={() => 'auto'}
          columns={columns}
          editMode="row"
          getRowId={(claims) => claims.uniqueID}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
      </Box>
    </div>
  )
}

export default TimeLossTable;