/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClaim = /* GraphQL */ `
  mutation CreateClaim(
    $input: CreateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    createClaim(input: $input, condition: $condition) {
      uniqueID
      claimID
      activeStatus
      planYear
      claimsManager
      claimStatus
      claimType
      newOngoing
      account
      dba
      injuryDate
      workerName
      priortlDays
      tlDays
      tlDaysDiff
      tlPTD
      tlPtdDiff
      tlRes
      followUpMgr
      followupFlag
      kosAnalysis
      toDo
      notes
      dateUpdated
      retroMember
      retroGroup
      dateUploaded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClaim = /* GraphQL */ `
  mutation UpdateClaim(
    $input: UpdateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    updateClaim(input: $input, condition: $condition) {
      uniqueID
      claimID
      activeStatus
      planYear
      claimsManager
      claimStatus
      claimType
      newOngoing
      account
      dba
      injuryDate
      workerName
      priortlDays
      tlDays
      tlDaysDiff
      tlPTD
      tlPtdDiff
      tlRes
      followUpMgr
      followupFlag
      kosAnalysis
      toDo
      notes
      dateUpdated
      retroMember
      retroGroup
      dateUploaded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClaim = /* GraphQL */ `
  mutation DeleteClaim(
    $input: DeleteClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    deleteClaim(input: $input, condition: $condition) {
      uniqueID
      claimID
      activeStatus
      planYear
      claimsManager
      claimStatus
      claimType
      newOngoing
      account
      dba
      injuryDate
      workerName
      priortlDays
      tlDays
      tlDaysDiff
      tlPTD
      tlPtdDiff
      tlRes
      followUpMgr
      followupFlag
      kosAnalysis
      toDo
      notes
      dateUpdated
      retroMember
      retroGroup
      dateUploaded
      createdAt
      updatedAt
      __typename
    }
  }
`;
