/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClaim = /* GraphQL */ `
  query GetClaim($uniqueID: ID!) {
    getClaim(uniqueID: $uniqueID) {
      uniqueID
      claimID
      activeStatus
      planYear
      claimsManager
      claimStatus
      claimType
      newOngoing
      account
      dba
      injuryDate
      workerName
      priortlDays
      tlDays
      tlDaysDiff
      tlPTD
      tlPtdDiff
      tlRes
      followUpMgr
      followupFlag
      kosAnalysis
      toDo
      notes
      dateUpdated
      retroMember
      retroGroup
      dateUploaded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClaims = /* GraphQL */ `
  query ListClaims(
    $uniqueID: ID
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClaims(
      uniqueID: $uniqueID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        uniqueID
        claimID
        activeStatus
        planYear
        claimsManager
        claimStatus
        claimType
        newOngoing
        account
        dba
        injuryDate
        workerName
        priortlDays
        tlDays
        tlDaysDiff
        tlPTD
        tlPtdDiff
        tlRes
        followUpMgr
        followupFlag
        kosAnalysis
        toDo
        notes
        dateUpdated
        retroMember
        retroGroup
        dateUploaded
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const claimByActiveStatus = /* GraphQL */ `
  query ClaimByActiveStatus(
    $activeStatus: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    claimByActiveStatus(
      activeStatus: $activeStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        uniqueID
        claimID
        activeStatus
        planYear
        claimsManager
        claimStatus
        claimType
        newOngoing
        account
        dba
        injuryDate
        workerName
        priortlDays
        tlDays
        tlDaysDiff
        tlPTD
        tlPtdDiff
        tlRes
        followUpMgr
        followupFlag
        kosAnalysis
        toDo
        notes
        dateUpdated
        retroMember
        retroGroup
        dateUploaded
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const claimsByClaimStatus = /* GraphQL */ `
  query ClaimsByClaimStatus(
    $claimStatus: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    claimsByClaimStatus(
      claimStatus: $claimStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        uniqueID
        claimID
        activeStatus
        planYear
        claimsManager
        claimStatus
        claimType
        newOngoing
        account
        dba
        injuryDate
        workerName
        priortlDays
        tlDays
        tlDaysDiff
        tlPTD
        tlPtdDiff
        tlRes
        followUpMgr
        followupFlag
        kosAnalysis
        toDo
        notes
        dateUpdated
        retroMember
        retroGroup
        dateUploaded
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const claimsByRetroMember = /* GraphQL */ `
  query ClaimsByRetroMember(
    $retroMember: String!
    $sortDirection: ModelSortDirection
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    claimsByRetroMember(
      retroMember: $retroMember
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        uniqueID
        claimID
        activeStatus
        planYear
        claimsManager
        claimStatus
        claimType
        newOngoing
        account
        dba
        injuryDate
        workerName
        priortlDays
        tlDays
        tlDaysDiff
        tlPTD
        tlPtdDiff
        tlRes
        followUpMgr
        followupFlag
        kosAnalysis
        toDo
        notes
        dateUpdated
        retroMember
        retroGroup
        dateUploaded
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const claimByActiveStatusAndRetroGroup = /* GraphQL */ `
  query ClaimByActiveStatusAndRetroGroup(
    $retroGroup: String!
    $activeStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    claimByActiveStatusAndRetroGroup(
      retroGroup: $retroGroup
      activeStatus: $activeStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        uniqueID
        claimID
        activeStatus
        planYear
        claimsManager
        claimStatus
        claimType
        newOngoing
        account
        dba
        injuryDate
        workerName
        priortlDays
        tlDays
        tlDaysDiff
        tlPTD
        tlPtdDiff
        tlRes
        followUpMgr
        followupFlag
        kosAnalysis
        toDo
        notes
        dateUpdated
        retroMember
        retroGroup
        dateUploaded
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
